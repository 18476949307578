<template>
  <div class="container mt-5 pb-5">
    <div class="row align-items-center jutify-content-start paragraph-03 text-neutral-05">
      <div class="col-auto pe-2">
        <router-link :to="{ name: 'HomePage' }" class="text-neutral-05"> Acasă </router-link>
      </div>
      <div class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div class="col-auto pe-2">
        <router-link :to="{ name: 'Blogs' }" class="text-neutral-05"> Articole </router-link>
      </div>
      <div class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div v-if="article.Category" class="col-auto pe-2">
        <router-link
          :to="{
            name: 'Blogs',
            params: {
              categoryName: $utils.StringToSlug(article.Category.Name),
              categoryId: article.Category.Id,
            },
          }"
          class="text-neutral-05"
        >
          {{ article.Category.Name }}
        </router-link>
      </div>
      <div v-if="article.Category" class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div v-if="article.Title" class="col-auto text-button-02 text-secondary-05">
        {{ $utils.Ellipsify(article.Title, 50) }}
      </div>
    </div>
    <div class="row mt-4 mb-5 mb-md-0">
      <div class="col-lg-7 mb-5 mb-lg-0">
        <img
          :src="article.ImgBase64"
          @error="$utils.ShowDefaultImage"
          class="article-img"
          alt="..."
        />
        <div class="heading-03 text-primary-05 mt-4 pt-1">{{ article.Title }}</div>
        <div class="row mt-2 pt-1 align-items-center">
          <div class="col-2 me-4 d-flex align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="vuesax/linear/calendar">
                <g id="calendar">
                  <path
                    id="Vector"
                    d="M6.6665 1.66675V4.16675"
                    stroke="#53666D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M13.3335 1.66675V4.16675"
                    stroke="#53666D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_3"
                    d="M2.9165 7.57495H17.0832"
                    stroke="#53666D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_4"
                    d="M17.5 7.08341V14.1667C17.5 16.6667 16.25 18.3334 13.3333 18.3334H6.66667C3.75 18.3334 2.5 16.6667 2.5 14.1667V7.08341C2.5 4.58341 3.75 2.91675 6.66667 2.91675H13.3333C16.25 2.91675 17.5 4.58341 17.5 7.08341Z"
                    stroke="#53666D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_5"
                    d="M13.0791 11.4167H13.0866"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_6"
                    d="M13.0791 13.9167H13.0866"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_7"
                    d="M9.99607 11.4167H10.0036"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_8"
                    d="M9.99607 13.9167H10.0036"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_9"
                    d="M6.91209 11.4167H6.91957"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_10"
                    d="M6.91209 13.9167H6.91957"
                    stroke="#53666D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>

            <span class="ms-2 text-button-03 text-neutral-06">{{ article.Date }}</span>
          </div>
          <div class="col-auto d-flex align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="vuesax/linear/eye">
                <g id="eye">
                  <path
                    id="Vector"
                    d="M12.9833 9.99993C12.9833 11.6499 11.6499 12.9833 9.99993 12.9833C8.34993 12.9833 7.0166 11.6499 7.0166 9.99993C7.0166 8.34993 8.34993 7.0166 9.99993 7.0166C11.6499 7.0166 12.9833 8.34993 12.9833 9.99993Z"
                    stroke="#53666D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M9.99987 16.8918C12.9415 16.8918 15.6832 15.1584 17.5915 12.1584C18.3415 10.9834 18.3415 9.00843 17.5915 7.83343C15.6832 4.83343 12.9415 3.1001 9.99987 3.1001C7.0582 3.1001 4.31654 4.83343 2.4082 7.83343C1.6582 9.00843 1.6582 10.9834 2.4082 12.1584C4.31654 15.1584 7.0582 16.8918 9.99987 16.8918Z"
                    stroke="#53666D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>

            <span class="ms-2 text-button-03 text-neutral-06">{{ article.NumberOfViews }}</span>
          </div>
        </div>
        <div v-html="article.Content" class="mt-4 text-neutral-05"></div>
      </div>
      <div class="col-1 d-none d-lg-block"></div>
      <div class="col">
        <div v-if="recentArticles && recentArticles.length > 0">
          <!--recommended articles-->
          <div class="recommended-articles heading-07 text-secondary-03 text-center mt-3 mb-4">
            Articole sugerate
          </div>
          <a
            :href="`/articol/${$utils.StringToSlug(recent.Title)}/${recent.Id}`"
            v-for="(recent, index) in recentArticles"
            :key="index"
            class="row align-items-center pb-3 mb-3"
            :class="{ 'border-bottom-article': index < recentArticles.length - 1 }"
          >
            <div class="col-4 me-1 ps-0">
              <img :src="recent.ImgBase64" @error="$utils.ShowDefaultImage" class="article-image" />
            </div>
            <div class="col pe-0 ps-0">
              <div class="article-category">{{ recent.CategoryName }}</div>
              <div class="heading-08 text-neutral-06">{{ recent.Title }}</div>
              <div class="row mt-2 align-items-center">
                <div class="col-6 d-flex align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="vuesax/linear/calendar">
                      <g id="calendar">
                        <path
                          id="Vector"
                          d="M6.6665 1.66675V4.16675"
                          stroke="#53666D"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M13.3335 1.66675V4.16675"
                          stroke="#53666D"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_3"
                          d="M2.9165 7.57495H17.0832"
                          stroke="#53666D"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_4"
                          d="M17.5 7.08341V14.1667C17.5 16.6667 16.25 18.3334 13.3333 18.3334H6.66667C3.75 18.3334 2.5 16.6667 2.5 14.1667V7.08341C2.5 4.58341 3.75 2.91675 6.66667 2.91675H13.3333C16.25 2.91675 17.5 4.58341 17.5 7.08341Z"
                          stroke="#53666D"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_5"
                          d="M13.0791 11.4167H13.0866"
                          stroke="#53666D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_6"
                          d="M13.0791 13.9167H13.0866"
                          stroke="#53666D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_7"
                          d="M9.99607 11.4167H10.0036"
                          stroke="#53666D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_8"
                          d="M9.99607 13.9167H10.0036"
                          stroke="#53666D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_9"
                          d="M6.91209 11.4167H6.91957"
                          stroke="#53666D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_10"
                          d="M6.91209 13.9167H6.91957"
                          stroke="#53666D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                  </svg>

                  <span class="ms-2 text-button-03 text-neutral-06">{{ recent.Date }}</span>
                </div>
                <div class="col-auto d-flex align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="vuesax/linear/eye">
                      <g id="eye">
                        <path
                          id="Vector"
                          d="M12.9833 9.99993C12.9833 11.6499 11.6499 12.9833 9.99993 12.9833C8.34993 12.9833 7.0166 11.6499 7.0166 9.99993C7.0166 8.34993 8.34993 7.0166 9.99993 7.0166C11.6499 7.0166 12.9833 8.34993 12.9833 9.99993Z"
                          stroke="#53666D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M9.99987 16.8918C12.9415 16.8918 15.6832 15.1584 17.5915 12.1584C18.3415 10.9834 18.3415 9.00843 17.5915 7.83343C15.6832 4.83343 12.9415 3.1001 9.99987 3.1001C7.0582 3.1001 4.31654 4.83343 2.4082 7.83343C1.6582 9.00843 1.6582 10.9834 2.4082 12.1584C4.31654 15.1584 7.0582 16.8918 9.99987 16.8918Z"
                          stroke="#53666D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                  </svg>

                  <span class="ms-2 text-button-03 text-neutral-06">{{
                    recent.NumberOfViews
                  }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>

        <!--article categories-->
        <div class="recommended-articles heading-07 text-secondary-03 text-center mt-4 mb-4">
          Categoriile articolelor
        </div>
        <div class="row">
          <div v-for="(category, index) in categories" :key="index" class="col-12 mb-3">
            <router-link
              :to="{
                name: 'Blogs',
                params: {
                  categoryName: $utils.StringToSlug(category.Name),
                  categoryId: category.Id,
                },
              }"
              class="text-neutral-05"
            >
              <div class="article-category-box">
                <span class="title">
                  {{ category.Name }}
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="share-article-section">
      <div class="title">Distribuie articol:</div>
      <div class="row mt-2">
        <div class="col-auto">
          <button
            type="button"
            @click="ShareOnPlatform('facebookShare')"
            title="Distribuie pe Facebook"
          >
            <img :src="DisplaySocialMediaIcon('facebook')" />
          </button>
        </div>
        <div class="col-auto d-sm-none">
          <a
            :href="`whatsapp://send?text=${currentURL}`"
            data-action="share/whatsapp/share"
            title="Distribuie pe WhatsApp"
          >
            <img :src="DisplaySocialMediaIcon('whatsapp')" />
          </a>
          <button type="button"></button>
        </div>
        <div class="col-auto">
          <a
            :href="`mailto:?subject=Delicitate - Vezi acest articol!&amp;body=${currentURL}`"
            title="Trimite un e-mail cu acest articol"
          >
            <img :src="DisplaySocialMediaIcon('email')" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'ArticleView',
  props: {
    articolIdParam: {
      type: String,
      required: true,
    },
    articleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      article: {},
      recentArticles: [],
      categories: [],
      currentURL: '',
    };
  },
  methods: {
    ShareOnPlatform() {
      FB.ui(
        {
          method: 'share',
          href: window.location.href,
        },
        () => {}
      );
    },
    DisplaySocialMediaIcon(img) {
      return require(`@/assets/images/SocialMedia/${img}.svg`);
    },
    async GetArticle() {
      this.$store.state.loader = true;
      await this.$axios
        .get(`/Blog/get/${this.articolIdParam}`)
        .then((response) => {
          this.article = response.data;
          this.$store.state.loader = false;
        })
        .catch((error) => {
          this.$store.state.loader = false;
          if (error.response.status === 404) {
            this.$router.push({ name: 'NotFoundPage' });
          } else this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetRecentArticles() {
      this.$store.state.loader = true;
      this.$axios
        .get(`/Blog/getRecomandations/${this.articolIdParam}`)
        .then((response) => {
          this.recentArticles = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetCategories() {
      this.$store.state.loader = true;
      this.$axios
        .get('/Blog/getRecommendCategories')
        .then((response) => {
          this.categories = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
  },
  async created() {
    await this.GetArticle();
    if (Object.keys(this.article).length > 0) {
      this.GetRecentArticles();
      this.GetCategories();
      this.currentURL = window.location.href;
    }
  },
};
</script>

<style scoped>
.article-category-box .title {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.2em;
  color: var(--neutral-5);
  text-transform: uppercase;
  text-align: center;
}
.article-category-box:hover {
  box-shadow: 0px 4px 10px 0px #00000026;
}
.article-category-box {
  cursor: pointer;
  transition: 0.2s;
  border-radius: 8px;
  border: 1px solid var(--neutral-3);
  background-color: var(--neutral-1);
  min-height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.border-bottom-article {
  border-bottom: 1px solid var(--neutral-3);
}

.article-image {
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  height: 100px;
  object-fit: cover;
  width: 100%;
}

.article-category {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2em;
  color: var(--primary-5);
  text-transform: uppercase;
  margin-bottom: 5px;
}
.recommended-articles {
  padding-bottom: 8px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-image: linear-gradient(
    90deg,
    rgba(223, 210, 187, 0) 0%,
    rgba(223, 210, 187, 0.6) 9.37%,
    #bfa577 48.44%,
    rgba(223, 210, 187, 0.6) 90.1%,
    rgba(223, 210, 187, 0) 100%
  );
  border-image-slice: 1;
  width: 100%;
  max-width: 327px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.article-img {
  max-width: 702px;
  width: 100%;
  height: 270px;
  box-shadow: 10px 10px 40px -10px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
  display: block;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1245px;
  }
}
</style>
